body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
  background-color: aliceblue;
}

/* App */

/* body > #root > div {
  height: 100vh;
} */

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/lato300-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/lato300-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/lato400-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/lato400-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body {
  padding-top: 45px;
  background-color: #f3f4f8 !important;
  font-family: "Lato", sans-serif !important;
  color: black;
  font-size: 1rem;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.App {
  background-color: white !important;
  text-align: left;
  /* max-width: 375px; */
  max-width: 768px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.yacha-logo {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  margin-top: -45px;
}

.icons img {
  max-height: 2rem;
}

.container {
  background-color: white;
  padding-bottom: 1rem;
}

/* Login page */
.flex-container {
  background-color: #f3f4f8;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.75rem;
}
.flex-container,
.login-cotainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-container {
  background-color: white;
  box-shadow: 0 5px 10px -10px grey;
}

.login-header {
  font-family: "Lato light";
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
}

.login-header .icons {
  margin-left: auto;
}

.logos {
  margin-left: auto;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  width: 100%;
}
.form-control,
input {
  height: 48px;
}

button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hide-show-password-eye {
  margin-left: -40px !important;
  background-color: white !important;
  border-left: none !important;
  z-index: 9999 !important;
}

.hide-show-password-eye svg {
  color: gray;
}

.login-container .btn-primary:disabled {
  background-color: #27a8d1;
  border: #27a8d1;
}

.login-container .btn-primary {
  background-color: #27a8d1;
  border: #27a8d1;
}
.login-container .form-check-label.text-primary {
  color: #27a8d1 !important;
}

/* Yacht */
.tasks-container {
  box-shadow: 0 5px 10px -10px grey;
  padding-bottom: 2rem;
}

.box-yachts {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.box-task.task-active {
  font-weight: bold;
  color: #000;
}

.box-task,
.stop-task-dialog,
.finish-task-dialog,
.reset-task-dialog,
.warning-task-dialog, 
.early-departure-dialog, 
.pontoon-dialog, 
.dateTimePicker {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5rem 1rem;
  flex-wrap: nowrap;
}

.task-name {
  display: flex;
  flex-basis: 30%;
  flex-shrink: 0;
}

.task-name .stateIcon {
  margin-left: auto;
  padding-left: .5rem;
}

.box-task.task-active {
  background-color: #e3f0ff !important;
}

.box-task {
  color: #797979;
  background-color: #ffffff;
}

.yacht .card-header {
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  grid-gap: .5rem;
  gap: .5rem;
  padding: .5rem;
  flex-wrap: wrap-reverse;
}

.yachtname {
  display: flex;
  font-weight: bolder;
  font-size: 110%;
  align-items: center;
}
.yachtname .close {
  margin-left: auto;
}
.yacht .yacht-infos {
  color: gray;
}

.yacht.card.task-active {
  border: 1px solid #ed971c;
}

.yacht.card.yacht.card.task-active .card-header {
  background-color: #ed971c;
}
.yacht.card.task-done .card-header, .hasFixedWorkerForUser .yacht.card .card-header {
  background-color: #d1edd3 !important;
}

.yacht.card .card-header {
  background-color: #f6f6f6;
}
.yacht.card.task-done,
.yacht.card.task-done .box-task.task-done,
.box-task.task-done {
  background-color: #e0fee2;
}

.yacht.card.closed .close,
.yacht.card.closed .card-body {
  display: none;
}

.yacht.card.doingByOther {
  background-color: #fafafa;
}
.box-yachtname {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 1;
}

.stateIcon .orange {
  border: #ed971c;
  color: #ed971c;
}
.stateIcon .green, .fa-icon.green {
  color: green;
}

.yacht.card.true .details-vie,
.yacht.card.true .close,
.yacht.card.false .yacht-state,
.yacht.card.false .status-action {
  display: inherit;
}

.yacht.card.false .details-view,
.yacht.card.false .close,
.yacht.card.true .yacht-state,
.yacht.card.false .status-action {
  display: none;
}

/* For V1 to be hidden */
.yachtname.card-header button {
  display: none !important;
  margin-left: auto;
}

/* user */
.user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: gray;
  grid-gap: 1rem;
  gap: 1rem;
}
.box-user {
  padding: 2rem 0;
}

.username {
  font-size: 1.5rem;
}

.greeting,
.username {
  color: #000;
}

/* Navbar */
.navbar-header,
.offcanvas-header {
  background-color: #bee4f7 !important;
  z-index: 99999;;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

.box-languages .header {
  font-weight: bold;
}

.box-languages .nav-link.item {
  padding-left: 10px;
}

.btn-open.btn-primary {
  background-color: #ed971c;
  border: #ed971c;
}

.finish-task-dialog, .reset-task-dialog {
  background-color: #faf0a4;
}

.stop-task-dialog, .reset-task-dialog {
  background-color: #fff0f5;
  color: red;
}

.task-by-other {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.btn-success, .task-done .yacht-state.btn.btn-primary {
  background-color: #27a744 !important;
  border-color: #27a744 !important;
}

.btn.font-weight-bold {
  font-weight: bold;
}

.btn {
  padding: 0 0.75rem !important;
}

.warning-task-dialog {
  display: flex;
  justify-content: space-between;
}
.offline .warning-task-dialog {
  display: flex;
  justify-content: center;
}

.notification {
  background-color: #faf0a4;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}

.warning-task-dialog {
  padding: 2rem 1rem;
}
.warning-task-ok {
  color: green;
}

.card.card-closed .box-task button {
  display: none;
}

.team .role::after {
  content: ", ";
}

.team .role:last-of-type:after {
  content: "";
}

.card-body {
  border-top: 0.5px solid rgba(0, 0, 0, 0.125);
  margin-top: 1rem;
}

.navbar-bottom {
  color: gray;
  font-size: 10px;
  text-align: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  order: 10;
}

.card-container.task-active {
  order: 1;
}

/* No Tasks */
.no-tasks .card-body, .invalid-token .card-body {
  border: none;
  margin: auto;
}

/* logout as button in navbar*/
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  color: white;
  margin: auto;
  text-align: center;
  width: 100%;
}

/* Calendar */

.calendar {
  border-top: 0.5px solid rgba(0, 0, 0, 0.125);
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.react-calendar {
  margin: auto;
  margin-bottom: 1em;
  border: none !important;
}

.calendar input {
  font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
  /* text-align: center; */
}

.datePicker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.today {
  padding-top: 0.5em;
}

.previousDay,
.nextDay,
.calendar input {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 6px 10px 5px;
  border: 1px solid lightgray;
}

.previousDay:hover,
.nextDay:hover,
.calendar input:hover {
  opacity: 0.7;
}

.react-datepicker-wrapper {
  margin: 1em 0 !important;
}

.react-datepicker__input-container {
  text-align: center;
}

.nav-link:focus,
.nav-link:hover {
  color: white !important;
}


.app-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f4f8;
  color: gray;
  font-size: 10px;
  padding-bottom: 0.5rem;
}

.react-datepicker .react-datepicker__today-button {
  background: #0d6efd;
  border-radius: 4px;
  color: white;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.react-datepicker-wrapper .react-datepicker__input-container .react-datepicker__calendar-icon {
  margin-right: 1.5rem;
  padding: 0;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px !important;
  padding-left: 1.5rem !important;
}

button.logout {
  margin-bottom: 1rem;
}


.shifted {
  background-color: #ffdde1;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  padding: .75rem;
  padding-bottom: 0;
}

.box-yachts.shifted {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.shifted-title {
  color: #b20106;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: -2;
}

.shifted-day {
  color: #b47e80
}

.shifted .hide-show-password-eye {
  background-color: transparent !important;
}

.offcanvas.show:not(.hiding), .offcanvas.showing {
  z-index: 99999;
}

.box-task.task-active.task-inprogress-byOther {
  color: gray;
}

.yacht.card .yacha-icon {
  height: .75rem;
  padding-right: .25rem;
}

.yacht.card.card-closed .tasks-list {
  display: none;
}

.yacht.card.card-closed.task-active .tasks-list {
  display: flex;
}


.box-task .button, .box-task .status {
  margin-left: auto;
}

@media screen and (min-width: 600px) {
 .stateIcon {
  flex-basis: 5%;
 }
}
@media screen and (max-width: 600px) {
  body, .btn{
    font-size: 12px !important; 
  }

  .task-name {
    flex-basis: 35%;
    font-size: 13px;
  }

  .box-task, .stop-task-dialog, .finish-task-dialog, .warning-task-dialog, .reset-task-dialog {
    padding: .5rem;
    font-size: 11px;
  }

  .box-task.task-active .task-name{
    flex-shrink: 1;
  }

  .box-task.task-active {
    grid-gap: .5rem;
    gap: .5rem;
  }
  .yachtname {
    font-size: 13px;
  }
}


@media screen and (max-width: 340px) {
    .box-task {
      flex-wrap: wrap;
    }
    .task-name {
      flex-basis: 25%;
    }

    .yacht.card .yacha-icon {
      height: .75rem;
    }

    .marks.customer {
      flex-direction: column;
    }    
    
  }

  @media screen and (min-width: 361px) {
    .customer span {
      grid-gap: .125rem;
      gap: .125rem;
    }
  } 


@media screen and (max-width: 300px) {
    .box-task {
      flex-direction: column;
    }
    .box-task .button, .box-task .status {
      margin-left: 0;
    }
  }

.yacht.card {
  overflow: unset;
}

.card.card-closed .card-header {
  border-bottom: none;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.justify-content-end.navbar-nav {
  justify-content: flex-start !important;
}

.navbar-nav.pe-3 {
  padding-right: 0 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  max-height: -webkit-max-content !important;
  max-height: max-content !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
  pointer-events: none;
}

.box-yachts.shifted .hide-show-password-eye {
  z-index: 0 !important;
}

.early-departure-box, .pontoon-box {
  display: flex;
  flex-direction: column;
  background-color: #fff9c8;
  border-top: 1px solid white;
}

.early-departure-dialog {
  justify-content: start;
}
.early-departure-dialog label input{
  height: auto;
  margin-right: .25rem;
  font-weight: 400;
}

.dateTimePicker {
  align-items: center;
  justify-content: start;
}
.dateTimePicker .react-datepicker-wrapper {
  margin: 0 !important;
}

.dateTimePicker input {
  background-color: #f0f0f0;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 6px 10px 5px;
  height: auto;
}

.dateTimePicker .react-datepicker {
  display: flex;
}

.yacht-infos .marks {
  padding: .25rem 0;
}

.yacht-infos {
  margin: -.25rem 0;
}

.yacht-infos .marks .bold {
  font-weight: bold;
}

.fa-task-type {
  width: 1.25rem;
  height: 1.25rem;
  color: #9e9e9e;
  align-self: center;
}

.fa-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.fa-icon.red {
  color: #F44336;
}

.sky-blue {
  color: #2196F3
}

.progress {
  border-radius: 0 0 4px 4px !important;
  height: 35px !important; 
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: .5rem;
  align-items: center;
  grid-gap: .5rem;
  gap: .5rem;
}

.task-done .progress, .task-done .progress-bar {
  background-color: none green !important;  
}

.finish-task-dialog .btn, .stop-task-dialog .btn, .reset-task-dialog .btn {
  width: 50%;
  font-weight: bold;
}

.dialog-box {
  display: flex;
  flex-direction: column;
}

.dialog-box .buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

button.status-action.btn.btn-success.btn-lg {
  width: 100%;
  max-width: 250px;
  font-weight: bold;
}

.tasks-list {
  display: flex;
  flex-direction: column;
}

.box-task.customer_on_site, .dialog-box.customer_on_site, .finish-task-dialog.customer_on_site, .customer_on_site.pontoon-box {
  order: -1;
}

.marks.customer {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.customer span {
  display: flex;
  align-items: center;
}

.customer a {
  text-decoration: none;
}

.card-container.single-task .card-body {
  margin-top: 0;
}

.card-container.single-task .box-task {
  padding-bottom: 1rem;
}


.col.pontoon .form-label, .col.berth .form-label, .col.fremdsteg .form-check-label{
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .react-datepicker__view-calendar-icon input {
    min-width: 200px;
    }
    .today-home-btn svg {
      height: 1.25em;
      width: 1.25em;
    }
}

@media (min-width: 600px) {
    .today-home-btn svg {
      height: 1.25em !important;
      width: 1.25em !important;
    }
}

@media (max-width: 380px) {
  .calendar input {
    font-size: 12px !important  ;
  }
  .react-datepicker__view-calendar-icon input {
    max-width: 150px;
    }
}

.pontoon-box .pontoon-dialog {
  align-items: center;
  flex-wrap: wrap;
}
.col.pontoon, .col.fremdsteg, .col.berth {
  display: flex;
  align-items: center;
}

.pontoon-box .form-control {
  height: auto;
}

 
.pontoon-box .false.form-check-label {
  color: gray;
}
.pontoon-box .form-label {
  margin-bottom: 0;
}

.yacht-icons {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.yachtname {
  flex: 1 1;
}

.fa-gray {
  color: #9e9e9e;
}

.icon-container {
  width: 30px; /* Fixed width */
  height: 30px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-active .pontoon-box, .task-active .early-departure-box{
  display: inherit;
}

.pontoon-box , .early-departure-box {
    display: none;
}

.pontoon-early-departure {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.early-departure {
  font-weight: normal;
}

.yacht-wrapper.completed {
  order: 10 !important;
}

.marks .departure-info {
  display: flex;
  grid-gap: .25rem;
  gap: .25rem
}

.red .fa-task-type {
  color: #F44336;
}

.orange .fa-task-type {
  color: orange;
}

.gray .fa-task-type {
  color: gray;
}

a.item.link.video.nav-link {
  color: #4d4d4d;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  gap: .5rem;
}

a.item.link.video.nav-link:hover, a.item.link.video.nav-link:visited {
  color: #4d4d4d !important;
  opacity: 0.7
}

.help.navbar-nav {
  margin-bottom: 1rem;
}

.hasFixedWorkerForUser .yacht.card .card-header {
  background-color: #c0e3ff;
}

.hasFixedWorkerForUser  .yacht.card {
  border-color: #c0e3ff;
}

.hasFixedWorkerForUser  .yacht.card.task-done {
  border-color: #d1edd3 !important;
}

.hasFixedWorkerForUser .yacht.card {
  order: 2;
}

.datePicker.home {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: .5rem;
  gap: .5rem;
}

.today-home-btn:hover {
  opacity: 0.7;
}

.today-home-btn svg {
  width: 1em;
  height: 1em;
  font-size: 1rem;
}

.today-home-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding:  0 10px;
  border: 1px solid lightgray;
  height: 48px;
  width: 48px;
}

.previousDay,
.nextDay {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

.nav-dropdown-base a.nav-link {
    display: flex;
    border: thin solid rgba(77, 77, 77, 0.3);
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 9px 14px;
    color: #4d4d4d !important;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

